import Field from '@/shared/classes/form/field'
import http from '@/shared/http'
import { FieldType } from '@/shared/configs/field-type.config'
import { FilterInterface, LoadItemsPropsInterface } from '@/shared/interfaces/form.interface'
import ResponseInterface from '@/shared/interfaces/response.interface'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import { getQueryName } from '@/shared/helpers/query.helper'

export default class SelectField extends Field {
  type: FieldType = FieldType.select
  items: SelectItem[] = []

  setItems(items: SelectItem[]): SelectField {
    this.items = items
    return this
  }

  loadItems(loadItemsProps: LoadItemsPropsInterface): this {
    const endpoint = loadItemsProps.endpoint

    const searchParams = new URLSearchParams('')
    if (loadItemsProps.perPage) searchParams.set('per_page', loadItemsProps.perPage.toString())
    else searchParams.set('per_page', '9000')

    if (loadItemsProps.filters) {
      loadItemsProps.filters.forEach((filter: FilterInterface) => {
        searchParams.set(getQueryName(filter.type, filter.name), filter.value)
      })
    }

    http.get(`${ endpoint }?${ searchParams.toString() }`)
      .then((response: ResponseInterface<any>) => this.setItems(response.data.data.map((item: any) => new SelectItem()
        .setTitle(item[loadItemsProps.title]).setValue(item[loadItemsProps.value]))))

    return this
  }
}
