














import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import { DataTableHeaderType } from '@/shared/configs/data-table.config'
import Table from '@/components/data-table/Table.vue'
import Search from '@/components/data-table/Search.vue'

@Component({
  components: { Search, Table }
})
export default class DataTable extends Vue {
  @Prop() table!: DataTableBase

  created() {
    this.addActionButtons()
  }

  private addActionButtons() {
    if (this.table.actions.length === 0) return

    this.table.addHeader(
      new DataTableHeader()
        .setType(DataTableHeaderType.action)
        .setKey('action')
        .setTitle('')
    )
  }

  async refresh() {
    if (this.tableRef) return await this.tableRef.filter()
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
