










import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import { DataTableHeaderType, dataTableHeaderTypeMap } from '@/shared/configs/data-table.config'
import DefaultHeader from '@/components/data-table/headers/DefaultHeader.vue'

@Component
export default class HeaderTypes extends Vue {
  @Prop() table!: DataTableBase
  @Prop() props!: any

  getComponentName(type: DataTableHeaderType): any {
    if (dataTableHeaderTypeMap.hasOwnProperty(type)) {
      return dataTableHeaderTypeMap[type]
    }

    return DefaultHeader
  }
}
