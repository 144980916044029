export enum DataTableHeaderType {
  default = 'default',
  action = 'action',
  custom = 'custom',
}

export const dataTableHeaderTypeMap = {
  [DataTableHeaderType.default]: () => import('@/components/data-table/headers/DefaultHeader.vue'),
  [DataTableHeaderType.action]: () => import('@/components/data-table/headers/ActionHeader.vue'),
  [DataTableHeaderType.custom]: () => import('@/components/data-table/headers/CustomHeader.vue'),
}
