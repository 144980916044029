












import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class Search extends Vue {
  @Prop({ default: true }) setPage!: boolean
  searchQuery: string = ''
  debouncedSearch!: () => void

  mounted() {
    this.setSearchValue()

    this.$watch('$route', this.setSearchValue)
    this.debouncedSearch = _.debounce(this.search, 300)
    this.$watch('searchQuery', this.debouncedSearch)
  }

  search() {
    let { query }: any = this.$router.currentRoute
    const { name }: any = this.$router.currentRoute
    query = { ...query }

    this.searchQuery === '' ? delete query.q : (query.q = this.searchQuery)
    if (this.setPage) query.page = String(1)

    this.$router.push({ name, query }).catch(() => {})
  }

  private setSearchValue(): void {
    const { query } = this.$router.currentRoute
    this.searchQuery = query.q ? String(query.q) : ''
  }
}
