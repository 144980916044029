import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum ParameterType {
  number = 'number',
  decimal = 'decimal',
  text = 'text',
  date = 'date',
  boolean = 'boolean',
  list = 'list',
  matrix = 'matrix',
  array = 'array',
}

export const parameterTypes: SelectItem[] = [
  new SelectItem().setValue(ParameterType.number).setTitle('Number'),
  new SelectItem().setValue(ParameterType.decimal).setTitle('Decimal'),
  new SelectItem().setValue(ParameterType.text).setTitle('Text'),
  new SelectItem().setValue(ParameterType.date).setTitle('Date'),
  new SelectItem().setValue(ParameterType.boolean).setTitle('Boolean'),
  new SelectItem().setValue(ParameterType.list).setTitle('List'),
  new SelectItem().setValue(ParameterType.matrix).setTitle('Matrix'),
  new SelectItem().setValue(ParameterType.array).setTitle('Array'),
]
