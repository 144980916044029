import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import DataTableAction from '@/shared/classes/data-table/data-table-action'
import DataTableDefaultFilterInterface from '@/shared/interfaces/data-table-default-filter.interface'
import QueryFilterInterface from '@/shared/interfaces/query-filter.interface'

export default class DataTableBase {
  endpoint!: string
  loading: boolean = false
  headers: DataTableHeader[] = []
  data!: any
  meta!: any
  actions: DataTableAction[] = []
  searchVisible: boolean = true
  defaultFilters: DataTableDefaultFilterInterface[] = []
  queryFilters: QueryFilterInterface[] = []
  pagination: boolean = true
  groupBy!: string | string[]

  setEndpoint(endpoint: string): this {
    this.endpoint =  endpoint

    return this
  }

  setLoading(loading: boolean): this {
    this.loading = loading

    return this
  }

  setHeaders(headers: DataTableHeader[]): this {
    this.headers =  headers

    return this
  }

  addHeader(header: DataTableHeader): this {
    this.headers = [...this.headers, header]

    return this
  }

  setData(data: any): this {
    this.data =  data

    return this
  }

  setMeta(meta: any): this {
    this.meta =  meta

    return this
  }

  setActions(actions: DataTableAction[]): this {
    this.actions = actions

    return this
  }

  setSearchVisible(searchVisible: boolean): this {
    this.searchVisible = searchVisible

    return this
  }

  setDefaultFilters(defaultFilters: DataTableDefaultFilterInterface[]): this {
    this.defaultFilters = defaultFilters

    return this
  }

  setQueryFilters(queryFilters: QueryFilterInterface[]): this {
    this.queryFilters = queryFilters

    return this
  }

  setPagination(pagination: boolean = true): this {
    this.pagination = pagination

    return this
  }

  setGroupBy(groupBy: string | string[]): this {
    this.groupBy = groupBy

    return this
  }
}
