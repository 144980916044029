










import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Form from '@/components/form/Form.vue'

@Component({
  components: { Form },
})
export default class CreateParameterForm extends Vue {
  @Prop() forms!: FormBase[]
  @Prop() form!: FormBase

  get formRef() {
    return this.$refs.form
  }
}
