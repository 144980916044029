





















import { Component, Prop, Vue } from 'vue-property-decorator'
import Tab from '@/shared/classes/tabs/tab'

@Component
export default class Tabs extends Vue {
  @Prop() tabs!: Tab[]
  @Prop() meta!: any
  @Prop({ default: true }) hideSlider!: boolean
}
