


























import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import _ from 'lodash'
import http from '@/shared/http'
import ResponseInterface from '@/shared/interfaces/response.interface'
import Model from '@/shared/interfaces/model'
import { getQueryName } from '@/shared/helpers/query.helper'
import DataTableDefaultFilterInterface from '@/shared/interfaces/data-table-default-filter.interface'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import HeaderTypes from '@/components/data-table/headers/HeaderTypes.vue'
import QueryFilterInterface from '@/shared/interfaces/query-filter.interface'

@Component({
  components: { HeaderTypes }
})
export default class Table extends Vue {
  @Prop() table!: DataTableBase
  page: number = 1
  perPage: number = 200
  total: number = 0

  mounted() {
    this.filter()
    this.$watch('page', () => {
      let { query }: any = this.$router.currentRoute
      const { name }: any = this.$router.currentRoute
      query = { ...query }

      query.page = this.page

      this.$router.push({ name, query }).catch(() => {})
    })

    this.$watch('table.data', () => {
      if (this.total === 0) {
        this.total = this.table.data.length
      }
    })

    this.$watch('$route', () => {
      this.filter()
    }, { deep: true })
  }

  created() {
    this.setDefaults()
  }

  private setDefaults() {
    let { query }: any = this.$router.currentRoute
    query = { ...query }

    this.page = Number(_.get(query, 'page', this.page))
    this.perPage = Number(_.get(query, 'per_page', this.perPage))

    query.per_page = this.perPage.toString()

    this.$router.push({ name, query }).catch(() => {})
  }

  filter() {
    if (! this.table.endpoint) {
      this.total = this.table.data.length
      return
    }

    const query = this.$router.currentRoute.query

    const searchParams = new URLSearchParams('')
    let url = this.table.endpoint

    Object.keys(query).forEach((key: string) => searchParams.set(key, query[key] as string))

    this.table.defaultFilters.forEach((defaultFilter: DataTableDefaultFilterInterface) => {
      searchParams.set(getQueryName(defaultFilter.operator, defaultFilter.key), defaultFilter.value)
    })

    this.table.queryFilters.forEach((queryFilter: QueryFilterInterface) => {
      searchParams.set(queryFilter.key, queryFilter.value)
    })

    if (searchParams.toString().length > 0) url += `?${ searchParams.toString() }`

    if (url) this.table.loading = true

    return (
        url &&
        http.get(url).then((response: ResponseInterface<Model[]>) => {
          const { data } = response

          this.table.setData(data.data.map((item: Model) => item))
          this.table.setMeta(data.meta)
          this.total = data.meta.total
          this.table.loading = false
        })
    )
  }

  get headers(): any[] {
    return this.table.headers.map((header: DataTableHeader) => ({
      text: header.title,
      value: header.key,
      sortable: false,
    }))
  }
}
