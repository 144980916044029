








import { Component, Inject, Vue } from 'vue-property-decorator'
import Tabs from '@/components/tabs/Tabs.vue'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import DataTable from '@/components/data-table/DataTable.vue'
import EditAction from '@/components/data-table/default-actions/edit-action'
import DeleteAction from '@/components/data-table/default-actions/delete-action'
import Calculator from '@/shared/modules/calculator/calculator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import CreateParametersDialog from '@/components/parameters/CreateParametersDialog.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import Parameter from '@/shared/modules/parameter/parameter'
import Client from '@/shared/modules/client/client'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import http from '@/shared/http'
import EditParameterDialog from '@/components/parameters/EditParameterDialog.vue'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { DataTable, Tabs }
})
export default class Parameters extends Vue {
  @Inject('calculator') getCalculator!: () => Calculator

  table = new DataTableBase()
    .setEndpoint(`clients/${ this.getCalculator().client_uuid }/calculators/${ this.getCalculator().uuid }/parameters`)
    .setGroupBy('parent.name')
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setTitle('Name'),
      new DataTableHeader()
          .setKey('alias')
          .setTitle('Alias'),
      new DataTableHeader()
        .setKey('type')
        .setTitle('Type'),
      new DataTableHeader()
        .setKey('description')
        .setTitle('Description'),
      new DataTableHeader()
        .setKey('default')
        .setTitle('Default'),
      new DataTableHeader()
        .setKey('options')
        .setTitle('Options'),
      new DataTableHeader()
        .setKey('outputable')
        .setTitle('Outputable')
        .setClasses('status-badge')
        .setValueParser((value: string) => value ? 'Yes' : 'No')
        .setStyleParser((value: string) => {
          let background = 'rgba(54, 179, 83, 0.4)'
          let color = 'rgba(54, 179, 83, 1)'

          if (! value) {
            background = 'rgba(255, 128, 139, 0.4)'
            color = 'rgba(255, 128, 139, 1)'
          }

          return {
            container: {
              background,
              color,
              borderRadius: '4px',
              padding: '4px 15px',
            }
          }
        }),
      new DataTableHeader()
        .setKey('is_required')
        .setTitle('Required')
        .setClasses('status-badge')
        .setValueParser((value: string) => value ? 'Yes' : 'No')
        .setStyleParser((value: string) => {
          let background = 'rgba(54, 179, 83, 0.4)'
          let color = 'rgba(54, 179, 83, 1)'

          if (! value) {
            background = 'rgba(255, 128, 139, 0.4)'
            color = 'rgba(255, 128, 139, 1)'
          }

          return {
            container: {
              background,
              color,
              borderRadius: '4px',
              padding: '4px 15px',
            }
          }
        }),
    ])
    .setActions([
      new EditAction().setAction((item: Parameter) => {
        this.$store.dispatch(GlobalActions.showDialog, {
          maxWidth: 1500,
          component: EditParameterDialog,
          meta: {
            calculator: this.getCalculator(),
            parameter: item,
            onSuccess: () => {
              this.tableRef.refresh()

              this.$store.dispatch(GlobalActions.showSnackbar, {
                type: SnackBarType.success,
                message: 'Parameter was successfully updated'
              })
            }
          }
        })
      }),
      new DeleteAction().setAction((item: Parameter) => {
        this.$store.dispatch(GlobalActions.showDialog, {
          maxWidth: 400,
          component: AreYouSureDialog,
          meta: {
            onYes: () => http.delete(`clients/${ this.selectedClient.uuid }/calculators/${ this.getCalculator().uuid }/parameters/${ item.uuid }`)
              .then(() => {
                this.$store.dispatch(GlobalActions.showSnackbar, {
                  type: SnackBarType.success,
                  message: 'Parameter was successfully deleted'
                })

                this.tableRef.refresh()
              })
          }
        })
      })
    ])

  openAddDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 1500,
      component: CreateParametersDialog,
      meta: {
        calculator: this.getCalculator(),
        onSuccess: () => {
          this.tableRef.refresh()

          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'Parameter was successfully created'
          })
        }
      }
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
