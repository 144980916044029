import _ from 'lodash'
import { DataTableHeaderType, dataTableHeaderTypeMap } from '@/shared/configs/data-table.config'
import { StyleParserInterface } from '@/shared/interfaces/data-table-style-parser.interface'

export default class DataTableHeader {
  key!: string
  title!: string
  classes: string = ''
  type: string = DataTableHeaderType.default
  styleParser!: (value: any, item: any) => StyleParserInterface
  meta!: (value: any, item: any) => any
  valueParser: (value: any, item?: any) => any = (value: any) => value

  setKey(key: string): this {
    this.key = key

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setClasses(classes: string): this {
    this.classes = classes

    return this
  }

  setType(type: string): this {
    this.type = type

    return this
  }

  setStyleParser(styleParser: (value: any, item: any) => StyleParserInterface): this {
    this.styleParser = styleParser

    return this
  }

  setValueParser(valueParser: (value: any, item?: any) => any): this {
    this.valueParser = valueParser

    return this
  }

  setMeta(meta: (value: any, item: any) => any): this {
    this.meta = meta

    return this
  }

  get component(): any {
    return _.get(dataTableHeaderTypeMap, this.type)
  }
}
