












import { Component, Prop, Vue } from 'vue-property-decorator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import PageHeader from '@/components/PageHeader.vue'

@Component({
  components: { PageHeader }
})
export default class AreYouSureDialog extends Vue {
  @Prop() meta: any
  loading: boolean = false

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  async onYes() {
    const { onYes } = this.meta
    this.loading = true
    onYes && await onYes()
    this.loading = false
    this.closeDialog()
  }

  onNo() {
    const { onNo } = this.meta
    this.closeDialog()
    onNo && onNo()
  }
}
