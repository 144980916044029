import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import _ from 'lodash'
import { StyleParserInterface } from '@/shared/interfaces/data-table-style-parser.interface'

@Component
export default class AbstractHeader extends Vue {
  @Prop() header!: DataTableHeader
  @Prop() table!: DataTableBase
  @Prop() item!: any

  get value(): any {
    return _.get(this.item, this.header.key, '-')
  }

  get styleParser(): StyleParserInterface | null {
    if (! this.header.styleParser) return null
    if (! this.header.styleParser(this.value, this.item)) return null

    return this.header.styleParser(this.value, this.item)
  }

  get containerStyles(): any {
    return this.styleParser ? this.styleParser.container : {}
  }
}
