























import {Component, Prop, Vue} from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import User from '@/shared/modules/user/user'
import {AuthGetters} from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import can from '@/shared/helpers/can'
import {Permission} from '@/shared/configs/permission.config'
import {GlobalActions} from '@/shared/store/global/global.actions'
import CreateParameterForm from '@/components/parameters/CreateParameterForm.vue'
import _ from 'lodash'
import SelectField from '@/shared/classes/form/fields/select/select-field'
import {ParameterType, parameterTypes} from '@/shared/configs/parameter-types'
import Field, {FieldSize} from '@/shared/classes/form/field'
import {FieldType} from '@/shared/configs/field-type.config'
import {FilterOperator} from '@/shared/classes/data-table/data-table-filter'

@Component({
  components: { CreateParameterForm, Form, PageHeader },
  methods: { can },
})
export default class CreateParametersDialog extends Vue {
  @Prop() meta!: any
  Permission = Permission

  templateForm: FormBase = new FormBase()
    .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/${ this.meta.calculator.uuid }/parameters`)
    .setValidateBefore(`clients/${ this.selectedClient.uuid }/calculators/${ this.meta.calculator.uuid }/parameters/validate`)
    .setFields([
      new SelectField()
        .setKey('type')
        .setTitle('Type')
        .setItems(parameterTypes)
        .setSize(FieldSize.twoTwelfth),
      new Field()
        .setKey('name')
        .setTitle('Name')
        .setSize(FieldSize.twoTwelfth),
      new Field()
        .setKey('default')
        .setTitle('Default')
        .setSize(FieldSize.twoTwelfth)
        .setVisibleIf((values: any) => {
          return ! _.get(values, 'multiple')
        }),
      new Field()
          .setType(FieldType.text)
          .setKey('alias')
          .setTitle('Alias')
          .setSize(FieldSize.quarter),
      new Field()
        .setType(FieldType.checkbox)
        .setKey('is_required')
        .setTitle('Required')
        .setSize(FieldSize.oneTwelfth),
      new Field()
        .setType(FieldType.checkbox)
        .setKey('outputable')
        .setTitle('Outputable')
        .setSize(FieldSize.oneTwelfth),
      new Field()
        .setType(FieldType.checkbox)
        .setKey('multiple')
        .setTitle('Multiple')
        .setSize(FieldSize.oneTwelfth)
        .setOnChange((value: boolean, fullKey?: string, form?: FormBase) => {
          const found: Field | undefined = form?.fields.find((field: Field) => field.key === 'description')

          if (found && value) found.setSize(FieldSize.fiveTwelfth)
          if (found && ! value) found.setSize(FieldSize.quarter)
        }),
      new Field()
        .setType(FieldType.comboBox)
        .setTitle('Options')
        .setKey('options')
        .setVisibleIf((values: any) => {
          return _.get(values, 'type') === ParameterType.list
        }),
      new Field()
          .setType(FieldType.textArea)
          .setKey('description')
          .setTitle('Description')
          .setSize(FieldSize.half)
          .setRows(1),
      new SelectField()
        .setKey('parent_uuid')
        .setTitle('Parent')
        .setSize(FieldSize.half)
        .loadItems({
          endpoint: `clients/${ this.selectedClient.uuid }/calculators/${ this.meta.calculator.uuid }/parameters`,
          value: 'uuid',
          title: 'name',
          filters: [
            {
              type: FilterOperator.equals,
              name: 'type',
              value: ParameterType.array,
            }
          ]
        })
        .setVisibleIf((values: any) => {
          return _.get(values, 'type') !== ParameterType.matrix
              && _.get(values, 'type') !== ParameterType.array
        }),
      new Field()
        .setType(FieldType.comboBox)
        .setTitle('Default')
        .setKey('default_multiple')
        .setSize(FieldSize.half)
        .setVisibleIf((values: any) => {
          return _.get(values, 'multiple')
        }),
      new Field()
        .setType(FieldType.numeric)
        .setKey('output_precision')
        .setTitle('Output precision')
        .setSize(FieldSize.half)
        .setVisibleIf((values: any) => {
          return _.get(values, 'type') === ParameterType.decimal
        }),
    ])
    .setSubmit(false)

  forms: FormBase[] = [this.templateForm]

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  async submitForms() {
    let validationError: boolean = false

    const { createParameterForm }: any = this.$refs

    if (createParameterForm.length > 1)
      await Promise.all(createParameterForm.map(async (component: any) => {
         if (await component.formRef.submit(true)) validationError = true
      }))

    if (validationError) return

    await Promise.all(createParameterForm.map(async (component: any) => {
      validationError = await component.formRef.submit()
    }))

    if (validationError) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  deleteParameter(index: number) {
    this.forms = this.forms.filter((value: FormBase, i: number) => i !== index)
  }

  addParameter() {
    this.forms.push(_.cloneDeep(this.templateForm))
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
