export default class DataTableAction {
  icon!: any
  title!: string
  action!: (item: any) => void
  permissions!: string[]

  setIcon(icon: any): this {
    this.icon = icon

    return this
  }

  setTitle(title: any): this {
    this.title = title

    return this
  }

  setAction(action: (item: any) => void): this {
    this.action = action

    return this
  }

  setPermissions(permissions: string[]): this {
    this.permissions = permissions

    return this
  }
}
